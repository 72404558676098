/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";

// IMPORT CUSTOM CSS STYLESHEET
import 'assets/css/style.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

const user_login = JSON.parse(localStorage.getItem("user_login"));
const path = window.location.pathname;
const publicPath = ["/authentication/sign-in","/swagger-ui"];

if (!user_login) {
	if (!publicPath.includes(path)) {
		window.location.href = "/authentication/sign-in";
	}
} else {
	// do user login
}

root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <App />
    </SoftUIControllerProvider>
  </BrowserRouter>
);
