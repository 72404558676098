// import hooks.
import { useEffect, useState } from "react";
import axios from "assets/api/AxiosModule";
import ApiRequests from "assets/api/ApiRequests";
import React, {Component} from "react";
import $ from "jquery";

// Soft UI Dashboard PRO React example components
import StatusUserDoughnutChart from "examples/Charts/DoughnutCharts/StatusUserDoughnutChart";

let dt = new Date;
const fromDate = new Date(dt.getFullYear(), dt.getMonth(), 1);
const toDate = dt;
const appId = 1;
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
function formatDmy(date){
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
  
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
  
    const formattedToday = dd + ' ' + monthNames[mm-1].substr(0, 3) + ' ' + yyyy;
    return formattedToday;
}

function StatusUserDoughnutChartComponent(){
/// initialize state using useState
    const [data, setData] = useState([]);
/// axios req & res
    const getPassportLogs = async (params) => {
        try{
            const response = await axios
            .get(`${ApiRequests.getPassportLogs}` + params)
            .then((response) => {
            setData(response.data.message);
           //console.log(response);
        });
        }catch(err){
            if(err.response){
            //Not in the 200 response range.
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            }
            else{
                console.log(`Error: ${err.message}`);
            }
        }
    }
/// tell React that your component needs to do something after render.
    useEffect(() => {  
      //getPassportLogs();
      let params = `?fromDate=${formatDate(fromDate)}&toDate=${formatDate(toDate)}&appId=${appId}`;
      getPassportLogs(params);

      mobiscroll.datepicker("#input-picker-status-user-doughnut-chart",{
          controls: ['calendar'],
          select:'range',
          touchUi: true,
          dateFormat: 'D MMM YYYY',
      });
      $("#input-picker-status-user-doughnut-chart").val(formatDmy(fromDate) + " - " + formatDmy(toDate));
      
      $(document).on("click","#openCalendarStatusUserDoughnutChart",function(){
          $("#input-picker-status-user-doughnut-chart").trigger("click");
      });

      $(document).on("change","#input-picker-status-user-doughnut-chart",function(){
          let rangePicker = $(this).val().split(" - ");
          let customFromDate = new Date(rangePicker[0]);
          let customToDate = new Date(rangePicker[1]);
          customFromDate = formatDate(customFromDate);
          customToDate = formatDate(customToDate);
          params = `?fromDate=${customFromDate}&toDate=${customToDate}&appId=${appId}`;
          getPassportLogs(params);
        });
    }, []);
    //console.log(data);
    //console.log(data?.newUsers);
    //console.log(data?.uniqueUsers);
    const values = [data.newUsers?data.newUsers:0, data.uniqueUsers?data.uniqueUsers:0];
    
    const StatusUserDoughnutChartData = {
        labels: ["New Users", "Active Users",],
        datasets: {
            label: "User Status",
            backgroundColors: ["success", "secondary",],
            //data: [55, 102],
            data: values,
        },
    };
    return(
        <>
            <StatusUserDoughnutChart
                title="New vs. Active Users"
                chart={StatusUserDoughnutChartData}
            />
        </>
    );
}
export default StatusUserDoughnutChartComponent;