// import hooks.
import { useEffect, useState } from "react";
import axios from "assets/api/AxiosModule";
import ApiRequests from "assets/api/ApiRequests";
import React, {Component} from "react";
import $ from "jquery";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DataTable from "examples/Tables/DataTable";

// Data
//import dataTableData from "layouts/dashboard/data/dataTableData";

let dt = new Date;
const fromDate = new Date(dt.getFullYear(), dt.getMonth(), 1);
const toDate = dt;
const appId = 1;
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
function formatDmy(date){
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
  
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
  
    const formattedToday = dd + ' ' + monthNames[mm-1].substr(0, 3) + ' ' + yyyy;
    return formattedToday;
}

function UserOverviewTable() {
/// initialize state using useState
  const [data, setTableData] = useState([]);
  const getUserOverview = async (params) => {
    try{
      const response = await axios
      .get(`${ApiRequests.getUserOverview}` + params)
      .then((response) => {
        //response.data
        setTableData(response.data.message);
        //console.log(response.data);
    });
    }catch(err){
      if(err.response){
        //Not in the 200 response range.
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      }
      else{
        console.log(`Error: ${err.message}`);
      }
    }
  };
/// tell React that your component needs to do something after render.
  useEffect(() => {  
    //getUserOverview();
    let params = `?fromDate=${formatDate(fromDate)}&toDate=${formatDate(toDate)}&appId=${appId}`;
    getUserOverview(params);

    mobiscroll.datepicker("#input-picker-user-overview-tbl",{
        controls: ['calendar'],
        select:'range',
        touchUi: true,
        dateFormat: 'D MMM YYYY',
    });
    $("#input-picker-user-overview-tbl").val(formatDmy(fromDate) + " - " + formatDmy(toDate));
    
    $(document).on("click","#openCalendarUserOverviewTbl",function(){
        $("#input-picker-user-overview-tbl").trigger("click");
    });

    $(document).on("change","#input-picker-user-overview-tbl",function(){
        let rangePicker = $(this).val().split(" - ");
        let customFromDate = new Date(rangePicker[0]);
        let customToDate = new Date(rangePicker[1]);
        customFromDate = formatDate(customFromDate);
        customToDate = formatDate(customToDate);
        params = `?fromDate=${customFromDate}&toDate=${customToDate}&appId=${appId}`;
        getUserOverview(params);
      });
  }, []);
//console.log(data);
/// initialize column data.
  const data_columns = [
    { Header: "name", accessor: "name", width: "36%" },
    { Header: "status", accessor: "status" },
    { Header: "cups scanned", accessor: "cups_scanned" },
    { Header: "current points", accessor: "current_points"},
    { Header: "date created", accessor: "date_created" },
    { Header: "points redeemed", accessor: "points_redeemed" },
  ];

  let data_rows = [];
/// initialize row data.
  if (data.length>0){
    if (Array.isArray(data)) {
      data_rows = data.map((row) => ({
        name: row?.userId,
        status: row?.status,
        cups_scanned: row?.cupsScanned,
        current_points: row?.currentPoints,
        date_created: row?.dateCreated.split('-').reverse().join('/'),
        //date_created: row?.dateCreated,
        points_redeemed: row?.pointsRedeemed,
      }));
    }
  }
  //console.log(data_rows);
/// set and assign data to table component.
/**/
  const dataTableData = {
    columns: data_columns,
    rows: data_rows,
  };

  return (
    <>
      <Card>
        {/*<SoftBox pt={3} pb={0} px={3} lineHeight={1}>*/}
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={3} pb={0} px={3} lineHeight={1}>
          <SoftTypography variant="h5">
            User Overview
          </SoftTypography>
          <SoftTypography variant="button" color="secondary">
            <span id="openCalendarUserOverviewTbl" className="cs-calendar"/>
            <input style={{width:"90%"}} className="calendar-picker cs-calendar" id="input-picker-user-overview-tbl"/>
          </SoftTypography>
        </SoftBox>
        <DataTable table={dataTableData} />
      </Card>
    </>
  );
}

export default UserOverviewTable;
