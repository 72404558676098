// import hooks.
/** 
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
*/

// Soft UI Dashboard React examples
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import { useEffect, useState } from "react";
import axios from "assets/api/AxiosModule";
import ApiRequests from "assets/api/ApiRequests";
import $ from "jquery";
let dt = new Date;
const fromDate = new Date(dt.getFullYear(), dt.getMonth(), 1);
const toDate = dt;
const appId = 1;
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

function formatDmy(date){
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = dd + ' ' + monthNames[mm-1].substr(0, 3) + ' ' + yyyy;
  return formattedToday;
}

function LineChartComponent(){

    const [reactlabels, setLabels] = useState([]);

    const [reactdatas, setDatas] = useState([]);

    const getPassportScanned = async (params) => {
        try{

            const response = await axios
            .get(ApiRequests.getPassportLogsTable + params)
            .then((response) => {

            let resDates = response.data.message.passportLogsTable;
            let chartTabs = {};
            chartTabs["jan"] = 0;
            chartTabs["feb"] = 0;
            chartTabs["mar"] = 0;
            chartTabs["apr"] = 0;
            chartTabs["may"] = 0;
            chartTabs["jun"] = 0;
            chartTabs["jul"] = 0;
            chartTabs["aug"] = 0;
            chartTabs["sep"] = 0;
            chartTabs["oct"] = 0;
            chartTabs["nov"] = 0;
            chartTabs["dec"] = 0;

            let singles = {};

            let labels = [];
            let datas = [];
            let counter = 0;
            for (const property in resDates) {
              counter+=1;
            }

            let counterYear = 0;

            let years = {};
            let indexByYear = {};

            if (resDates) {

              for (const property in resDates) {
                let d = new Date(property);
                if (indexByYear[d.getFullYear()]>0) {
                  indexByYear[d.getFullYear()] += resDates[property];
                } else {
                  indexByYear[d.getFullYear()] = 0;
                  indexByYear[d.getFullYear()] += resDates[property];
                }

              }

              for (const property in indexByYear) {
                counterYear +=1;
              }

              if (counter<=40) {
                for (const property in resDates) {
                  if (singles[property]>0){
                    singles[property] += resDates[property];
                  } else {
                    singles[property] = 0; 
                    singles[property] += resDates[property];
                  }
                }

                for (const property in singles) {
                  labels.push(property);
                  datas.push(singles[property]);
                }

              } else {
                for (const property in resDates) {
                  let d = new Date(property);
                  let indexByMonth = monthNames[d.getMonth()].toLowerCase();
                  indexByMonth = indexByMonth.substr(0,3)
                  chartTabs[indexByMonth] += resDates[property];
                }
                labels = ["Jan","Feb","Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                datas = [chartTabs["jan"], chartTabs["feb"], chartTabs["mar"], chartTabs["apr"], chartTabs["may"], chartTabs["jun"], chartTabs["jul"], chartTabs["aug"], chartTabs["sep"], chartTabs["oct"], chartTabs["nov"], chartTabs["dec"]];
              }

            }

            if (counterYear>1) {
              labels = [];
              datas = [];
              for (const property in indexByYear) {
                labels.push(property);
                datas.push(indexByYear[property]);
              }
            }

            let labelformap = [];

            if (labels[0] !== "Jan") {

              for(var i=0;i<labels.length;i++) {
                labelformap.push(labels[i]);
              }
              
              const datamap = datas;
              let indexDataAfterSorting;
              datas = [];

              labels.sort();
              for(var i=0;i<labels.length;i++) {
                indexDataAfterSorting = labelformap.indexOf(labels[i]);
                datas.push(datamap[indexDataAfterSorting]);
              }

            }

            setDatas(datas);
            setLabels(labels);
        });

        }catch(err){
            if(err.response){
            //Not in the 200 response range.

            }
            else{
                console.log(`Error: ${err.message}`);
            }
        }
    }

    useEffect(() => {
      
      let params = `?fromDate=${formatDate(fromDate)}&toDate=${formatDate(toDate)}&appId=${appId}`;
      getPassportScanned(params);

      mobiscroll.datepicker("#input-picker-passport-scanned",{
          controls: ['calendar'],
          select:'range',
          touchUi: true,
          dateFormat: 'D MMM YYYY',
      });

      $("#input-picker-passport-scanned").val(formatDmy(fromDate) + " - " + formatDmy(toDate));

      $(document).on("click","#openCalendarGradientPassportScanned",function(){
        $("#input-picker-passport-scanned").trigger("click");
      });

      $(document).on("change","#input-picker-passport-scanned",function(){
        let rangePicker = $(this).val().split(" - ");
        let customFromDate = new Date(rangePicker[0]);
        let customToDate = new Date(rangePicker[1]);
        customFromDate = formatDate(customFromDate);
        customToDate = formatDate(customToDate);
        let params = `?fromDate=${customFromDate}&toDate=${customToDate}&appId=${appId}`;
        getPassportScanned(params);
      });

    }, []);

    const gradientLineChartData = {
      labels: reactlabels,
      datasets: [
        {
          label: "Passports Scanned",
          color: "info",
          data: reactdatas, 
        },
      ],
    };

    return(
        <>
            <GradientLineChart
                title="Passports Scanned"
                height="100%"
                chart={gradientLineChartData}
            />
        </>
    );
}

export default LineChartComponent;