/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import SignInBasic from "layouts/authentication/sign-in";
import SignUpBasic from "layouts/authentication/sign-up";
import ResetBasic from "layouts/authentication/reset-password";
import VerificationBasic from "layouts/authentication/2-step-verification/basic";
import AboutUs from "layouts/about-us";
import PrivacyPolicy from "layouts/privacy-policy";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";

//react layouts custom pages.
import Dashboard from "layouts/dashboard";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import SettingsIcon from "examples/Icons/Settings";
import Basket from "examples/Icons/Basket";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";

const routes = [
/*  MAIN PAGES */
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route:"/dashboard",
    component:<Dashboard />,
    icon: <Shop size="12px" />,
    noCollapse: true,
  },
  {
    name:"About us",
    key:"about-us",
    route:"/about-us",
    component: <AboutUs />,
  },
  {
    name:"Privacy policy",
    key:"privacy-policy",
    route:"/privacy-policy",
    component: <PrivacyPolicy />,
  },
  {
    name: "Sign-in",
    key: "sign-in",
    route: "/authentication/sign-in",
    component: <SignInBasic />,
  },
  {
    name: "Sign-up",
    key: "sign-up",
    route: "/authentication/sign-up",
    component: <SignUpBasic />,
  },
  {
    name: "Reset password",
    key: "reset-password",
    route: "/authentication/reset-password",
    component: <ResetBasic />,
  },
  {
    name: "Error 404",
    key: "error-404",
    route: "/authentication/error/404",
    component: <Error404 />,
  },
  {
    name: "Error 500",
    key: "error-500",
    route: "/authentication/error/500",
    component: <Error500 />,
  },
  {
    name: "Basic",
    key: "basic",
    route: "/authentication/verification/basic",
    component: <VerificationBasic />,
  },
];

export default routes;
