// import hooks.
import { useEffect, useState } from "react";
import axios from "assets/api/AxiosModule";
import ApiRequests from "assets/api/ApiRequests";

// Soft UI Dashboard PRO React example components
import React, {Component} from "react";
import playstoreLogo from "assets/images/playstore-svg.svg";
import appleLogo from "assets/images/apple-svg.svg";
import AppDownloadDoughnutChart from "examples/Charts/DoughnutCharts/AppDownloadDoughnutChart";
import $ from "jquery";

let dt = new Date;
const fromDate = new Date(dt.getFullYear(), dt.getMonth(), 1);
const toDate = dt;
const appId = 1;
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

function formatDmy(date){
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = dd + ' ' + monthNames[mm-1].substr(0, 3) + ' ' + yyyy;
  return formattedToday;
}

function AppDownloadDoughnutChartComponent () {

	const [labels, setLabels] = useState([]);
	const [logos, setLogos] = useState([]);
	const [datas, setDatas] = useState([]);

    const getAppDownload = async (params) => {
        try{
            const response = await axios
            .get(ApiRequests.getAppDownload + params)
            .then((response) => {
            let res = response.data.message;
            setLabels(["Google Play", "iOS"]);
            setLogos([playstoreLogo, appleLogo]);
            setDatas([res.android?res.android:0, res.iphone?res.iphone:0]);
        });
        }catch(err){
            if(err.response){
            //Not in the 200 response range.
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            }
            else{
                console.log(`Error: ${err.message}`);
            }
        }
    }

    useEffect(() => {  

      let params = `?fromDate=${formatDate(fromDate)}&toDate=${formatDate(toDate)}&appId=${appId}`;
      getAppDownload(params);

      mobiscroll.datepicker("#input-picker-app-download",{
          controls: ['calendar'],
          select:'range',
          touchUi: true,
          dateFormat: 'D MMM YYYY',
      });

      $("#input-picker-app-download").val(formatDmy(fromDate) + " - " + formatDmy(toDate));

      $(document).on("click","#openCalendarGradientAppDownload",function(){
        $("#input-picker-app-download").trigger("click");
      });

      $(document).on("change","#input-picker-app-download",function(){
        let rangePicker = $(this).val().split(" - ");
        let customFromDate = new Date(rangePicker[0]);
        let customToDate = new Date(rangePicker[1]);
        customFromDate = formatDate(customFromDate);
        customToDate = formatDate(customToDate);
        params = `?fromDate=${customFromDate}&toDate=${customToDate}&appId=${appId}`;
        getAppDownload(params);
      });

    }, []);

    const AppDownloadData = {
	  images: logos,
	  labels: labels,
	  datasets: {
	    label: "App Donwloads",
	    backgroundColors: ["info", "success"],
	    data: datas,
	  },
	};

    return(
        <>
            <AppDownloadDoughnutChart
                title="App Downloads"
                chart={AppDownloadData}
            />
        </>
    );
	
}
export default AppDownloadDoughnutChartComponent;