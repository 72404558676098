import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";
import { useEffect } from "react";
import $ from "jquery";
import Swal from "sweetalert2";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {

    localStorage.removeItem("user_login");

    $(document).on("click","#login",function(e){
      e.preventDefault();
      let invalid = 0;
      let email = $("#email").val();
      let password = $("#password").val();

      if (email!=="admin@gmail.com") {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: 'Invalid email!',
          type:"error",
        })
        invalid+=1;
      }

      if (password!=="123456") {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: 'Invalid password!',
          type:"error",
        })
        invalid+=1;
      }

      if (invalid==0) {
        localStorage.setItem("user_login",JSON.stringify({"email":email}));
        
        setTimeout(function(){
          window.location.href = window.location.origin + "/dashboard";
        },2000);
      } else {
        //
      }

    });
  },[]);

  return (
    <BasicLayout
      image={curved9}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Sign in
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={2}>
          <Socials />
        </SoftBox>
        <SoftBox p={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftInput id="email" type="email" placeholder="Email" required/>
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput id="password" type="password" placeholder="Password" required/>
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;Remember me
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton id="login"
              variant="gradient" 
              color="info" 
              fullWidth>
                sign in
              </SoftButton>
            </SoftBox>
{/*
            <Separator />
            <SoftBox mt={1} mb={3}>
              <SoftButton
                component={Link}
                to="/authentication/sign-up"
                variant="gradient"
                color="dark"
                fullWidth
              >
                sign up
              </SoftButton>
            </SoftBox>
*/}
            <SoftBox mt={1} textAlign="center">
              <SoftTypography 
                component={Link} 
                to="/authentication/reset-password"
                variant="button"
                color="text"
                fontWeight="regular">
                Forgot password?
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
