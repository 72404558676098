const pageOptions = 1;
const fromDate = '2000-01-01';
const toDate = '2030-12-31';
const appId = 1;
const ApiRequests = {
    //overview doughnut chart
    getOverview: `/getOverview`,
    //new vs active users doughnut chart
    getPassportLogs: `/getPassportLogs`,
    //new vs active users progress chart/bar
    getNewActiveUsers: `/getNewActiveUsers`,
    //statcard
    getStatCard: `/getDashboardCards?fromDate=${fromDate}&toDate=${toDate}&appId=${appId}`,
    //app download doughnut chart
    getAppDownload: `/getAppDownloads`,
    //user overview
    getUserOverview: `/getUserOverview`,
    // passport scanned
    getPassportLogsTable: `/getPassportLogsTable`,

};
export default ApiRequests;