// import hooks.
import { useEffect, useState } from "react";
import axios from "assets/api/AxiosModule";
import ApiRequests from "assets/api/ApiRequests";
import React, {Component} from "react";
import $ from "jquery";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftProgress from "components/SoftProgress";
import SoftTypography from "components/SoftTypography";

let dt = new Date;
const fromDate = new Date(dt.getFullYear(), dt.getMonth(), 1);
const toDate = dt;
const appId = 1;
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
function formatDmy(date){
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
  
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
  
    const formattedToday = dd + ' ' + monthNames[mm-1].substr(0, 3) + ' ' + yyyy;
    return formattedToday;
}

function StatusUserProgressBar(){
/// initialize state using useState
    const [data, setData] = useState([]);
/// axios req & res
    const getNewActiveUsers = async (params) => {
        try{
            const response = await axios
            .get(`${ApiRequests.getNewActiveUsers}` + params)
            .then((response) => {
            setData(response.data.message);
           //console.log(response);
        });
        }catch(err){
            if(err.response){
            //Not in the 200 response range.
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            }
            else{
                console.log(`Error: ${err.message}`);
            }
        }
    }
/// tell React that your component needs to do something after render.
    useEffect(() => {  
      //getNewActiveUsers();
      let params = `?fromDate=${formatDate(fromDate)}&toDate=${formatDate(toDate)}&appId=${appId}`;
      getNewActiveUsers(params);

      mobiscroll.datepicker("#input-picker-status-user-progress-bar",{
          controls: ['calendar'],
          select:'range',
          touchUi: true,
          dateFormat: 'D MMM YYYY',
      });
      $("#input-picker-status-user-progress-bar").val(formatDmy(fromDate) + " - " + formatDmy(toDate));
      
      $(document).on("click","#openCalendarStatusUserProgressBar",function(){
          $("#input-picker-status-user-progress-bar").trigger("click");
      });

      $(document).on("change","#input-picker-status-user-progress-bar",function(){
          let rangePicker = $(this).val().split(" - ");
          let customFromDate = new Date(rangePicker[0]);
          let customToDate = new Date(rangePicker[1]);
          customFromDate = formatDate(customFromDate);
          customToDate = formatDate(customToDate);
          params = `?fromDate=${customFromDate}&toDate=${customToDate}&appId=${appId}`;
          getNewActiveUsers(params);
        });
    }, []);
    //console.log(data);
    //console.log(data?.passportsScanned);
    //console.log(data?.cupPointsEarned);
    //console.log(data?.newAndActiveUsers);
    //console.log(data?.uniqueUsers);
    //console.log(data?.numberOfDifferentBinsUsed);
    let passportsScannedVal = data?.passportsScanned/100;
    let cupPointsEarnedVal = data?.cupPointsEarned/100;
    let newAndActiveUsersVal = data?.newAndActiveUsers/100;
    let uniqueUsersVal = data?.uniqueUsers/100;
    let numberOfDifferentBinsUsedVal = data?.numberOfDifferentBinsUsed/100;
    
 return(
    <>
        <Card sx={{ height: "100%" }}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <SoftTypography variant="h5">
                    New vs. Active Users
                </SoftTypography>
                <SoftTypography variant="button" color="secondary">
                    <span id="openCalendarStatusUserProgressBar" className="cs-calendar"/>
                    <input style={{width:"90%"}} className="calendar-picker cs-calendar" id="input-picker-status-user-progress-bar"/>
                </SoftTypography>
            </SoftBox>
            <SoftBox py={3} px={3}>
                <SoftBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
                sx={{ listStyle: "none" }}
                >
                    <SoftBox component="li" w="100%" py={1} mb={0.5}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            color="dark"
                            textTransform="capitalize"
                        >
                            Passports Scanned
                        </SoftTypography>
                        <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            color="dark"
                            textTransform="capitalize"
                        >
                            {data?.passportsScanned}
                        </SoftTypography>
                        </SoftBox>
                        <SoftProgress variant="gradient" value={passportsScannedVal} />
                    </SoftBox>
                    <SoftBox component="li" w="100%" py={1} mb={0.5}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            color="dark"
                            textTransform="capitalize"
                        >
                            Cup points earned
                        </SoftTypography>
                        <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            color="dark"
                            textTransform="capitalize"
                        >
                            {data?.cupPointsEarned}
                        </SoftTypography>
                        </SoftBox>
                        <SoftProgress variant="gradient" value={cupPointsEarnedVal} />
                    </SoftBox>
                    <SoftBox component="li" w="100%" py={1} mb={0.5}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            color="dark"
                            textTransform="capitalize"
                        >
                            Number Of New Users
                        </SoftTypography>
                        <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            color="dark"
                            textTransform="capitalize"
                        >
                            {data?.newAndActiveUsers}
                        </SoftTypography>
                        </SoftBox>
                        <SoftProgress variant="gradient" value={newAndActiveUsersVal} />
                    </SoftBox>
                    <SoftBox component="li" w="100%" py={1} mb={0.5}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            color="dark"
                            textTransform="capitalize"
                        >
                            Scans By Unique Users
                        </SoftTypography>
                        <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            color="dark"
                            textTransform="capitalize"
                        >
                            {data?.uniqueUsers}
                        </SoftTypography>
                        </SoftBox>
                        <SoftProgress variant="gradient" value={uniqueUsersVal} />
                    </SoftBox>
                    <SoftBox component="li" w="100%" py={1} mb={0.5}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            color="dark"
                            textTransform="capitalize"
                        >
                            Number of different bins used
                        </SoftTypography>
                        <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            color="dark"
                            textTransform="capitalize"
                        >
                            {data?.numberOfDifferentBinsUsed}
                        </SoftTypography>
                        </SoftBox>
                        <SoftProgress variant="gradient" value={numberOfDifferentBinsUsedVal} />
                    </SoftBox>
                </SoftBox>
            </SoftBox>
        </Card>    
    </>
 );
}
export default StatusUserProgressBar;