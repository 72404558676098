// import library/hooks
import axios from "axios";
const auth_token = 'Bearer_eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTIzNDU2';
import remoteConfig from '../../remoteConfig.json';

/** initialize baseUrl to make a requests to the APIs */
const instance = axios.create({
    //baseURL: remoteConfig.baseUrl,
    baseURL: "https://api.packagingpassport.com",
    headers:{
        Authorization: `${auth_token}`,
    },
});
export default instance;