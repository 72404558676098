// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

function AppDownloadDoughnutChartItem({ color, image, title, percentage, hasBorder }) {
  const { borderWidth } = borders;
  const { light } = colors;

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ borderBottom: hasBorder ? `${borderWidth[1]} solid ${light.main}` : "none" }}
    >
      <Grid item xs={9}>
        <SoftBox display="flex" py={1.5} pl={2} pr={1} alignItems="center">
          <SoftBox
            bgColor={color}
            width="1.50rem"
            height="1.50rem"
            borderRadius="md"
            mr={2}
            lineHeight={0}
            variant="gradient"
          />
          {image && (
            <SoftBox mr={1}>
              <SoftAvatar src={image} size="sm" alt="title" />
            </SoftBox>
          )}
          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="div"
              variant="button"
              textTransform="none"
              fontWeight="regular"
            >
              {title}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid item xs={3}>
        <SoftBox py={0.8} px={1} textAlign="center">
          <SoftTypography variant="button" color="dark" fontWeight="bold">
            {percentage}
          </SoftTypography>
        </SoftBox>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of AppDownloadDoughnutChartItem
AppDownloadDoughnutChartItem.defaultProps = {
  color: "info",
  image: "",
  hasBorder: false,
};

// Typechecking props for the AppDownloadDoughnutChartItem
AppDownloadDoughnutChartItem.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  hasBorder: PropTypes.bool,
};

export default AppDownloadDoughnutChartItem;
