// import hooks.

import { useEffect, useState } from "react";
import axios from "assets/api/AxiosModule";
import ApiRequests from "assets/api/ApiRequests";
// @mui material components
import Grid from "@mui/material/Grid";
import React, {Component} from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Material UI Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DownloadIcon from '@mui/icons-material/Download';

function StatCard() {

    const [data, setData] = useState([]);

    const getStatCard = async () => {
        try{
            const response = await axios
            .get(`${ApiRequests.getStatCard}`)
            .then((response) => {
            setData(response.data.message);
           //console.log(response);
        });
        }catch(err){
            if(err.response){
            //Not in the 200 response range.
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            }
            else{
                console.log(`Error: ${err.message}`);
            }
        }
    }

    useEffect(() => {  
      getStatCard();
    }, []);

    return(
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <SoftBox mb={3}>
                <MiniStatisticsCard
                  title={{ text: "Total Users", fontWeight: "bold" }}
                  count={data?.totalUsersInSystem}
                  icon={{ color: "info", component: <AccountCircleIcon /> }}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <SoftBox mb={3}>
                <MiniStatisticsCard
                  title={{ text: "Total Passports Scanned", fontWeight: "bold" }}
                  count={data?.totalPassportsScanned}
                  icon={{ color: "info", component: <LanguageIcon /> }}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <SoftBox mb={3}>
                <MiniStatisticsCard
                  title={{ text: "Total Points Earned", fontWeight: "bold" }}
                  count={data?.totalPointsEarned}
                  icon={{ color: "info", component: <AssessmentIcon /> }}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <SoftBox mb={3}>
                <MiniStatisticsCard
                  title={{ text: "Total App Downloads", fontWeight: "bold" }}
                  count={data?.totalAppDownloads}
                  icon={{ color: "info", component: <DownloadIcon /> }}
                />
              </SoftBox>
            </Grid>
          </Grid>
        </>
    );
}
export default StatCard;