// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//Dashboard Page components.
import StatCard from "layouts/dashboard/components/StatCard";
import OverviewDoughnutChartComponent from "layouts/dashboard/components/OverviewDoughnutChartComponent";
import AppDownloadDoughnutChartComponent from "layouts/dashboard/components/AppDownloadDoughnutChartComponent";
import StatusUserDoughnutChartComponent from "layouts/dashboard/components/StatusUserDoughnutChartComponent";
import LineChartComponent from "layouts/dashboard/components/LineChartComponent";
import StatusUserProgressBar from "layouts/dashboard/components/StatusUserProgressBar";
import UserOverviewTable from "layouts/dashboard/components/UserOverviewTable";

function Dashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
{/** SECTION STAT CARD */}
        <SoftBox mb={3}>
          <StatCard />
        </SoftBox>
{/** SECTION AVAILABLE POINTS & PASSPORTS SCANNED */}
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <OverviewDoughnutChartComponent />
            </Grid>
            <Grid item xs={12} lg={6}>
              <LineChartComponent />
            </Grid>
          </Grid>
        </SoftBox>
{/** SECTION APP DOWNLOADS, NEWS V ACTIVE USERS(DOUGHTNUTCHART) & NEWS V ACTIVE USERS PROGRESS BAR */}
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={4}>
              <AppDownloadDoughnutChartComponent />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <StatusUserDoughnutChartComponent />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <StatusUserProgressBar />
            </Grid>
          </Grid>
        </SoftBox>
{/** SECTION USEROVERVIEW TABLE */}
        <SoftBox py={3} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserOverviewTable />
            </Grid>
          </Grid>
        </SoftBox>   
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
