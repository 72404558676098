// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import PageCustomLayout from "examples/LayoutContainers/PageCustomLayout";

// Images
import coffeeshopBrand from "assets/images/coffee-shop-brand-svg.svg";

function AboutUs(){
    return(
        <PageCustomLayout>
            <SoftBox pt={2}>
                <SoftBox>
                    <Grid container 
                        justifyContent="center"
                        spacing={{ xs:4, md:2, lg:1, xl:0 }}
                        alignItems="center" 
                    >
                        <Grid item xs={12} lg={10}>
                            <SoftBox>
                                <Link to="/">
                                    <SoftBox
                                        component="img"
                                        src={coffeeshopBrand}
                                        title="Coffee Shop Brand"
                                        alt="Coffee Shop Brand"
                                    />
                                </Link>
                                <SoftTypography mb={2} variant="h2" fontWeight="regular" color="dark">
                                    About Us
                                </SoftTypography>
                                <SoftTypography variant="body 2" fontWeight="bold" color="dark">
                                    Contact
                                    <SoftTypography component="a" href="mailto:info@coffeeshop.com" color="dark">
                                        &nbsp;info@coffeeshop.com&nbsp;
                                    </SoftTypography> 
                                    for more details.
                                </SoftTypography>    
                            </SoftBox>
                        </Grid>    
                    </Grid>
                </SoftBox>
            </SoftBox>
        </PageCustomLayout>
    );
}
export default AboutUs;